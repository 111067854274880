<template>
  <div class="hello">
    <h1 style="padding-top: 50px; color: #01314c">关于我们</h1>
    <el-divider>
      我们总是渴望更多用户来使用我们的系统
    </el-divider>
    <el-row style="margin-top: 100px">
      <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
        <div style="display: flex; margin-top: 60px">
          <img src="../assets/about-1.png" height="90%" width="90%" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="{span: 10, offset: 4}" :xl="{span: 10, offset: 4}" style="margin-top: 30px">
        <div>
          <el-card shadow="hover">
            <h3 style="margin-top: 0px; color: #01314c">我们的经历</h3>
            <p style="text-align: left; color: #666666">
              我们是曾经最早一批的全产业链自营型社区O2O生鲜模式的实践者。
              核心成员于2010-2015年期间，在广西南宁市运营过一个投资4000万的种销一体化的社区型生鲜便利O2O零售项目（鲜府菜铺/鲜府时刻）
            </p>
          </el-card>
          <el-card shadow="hover" style="margin-top: 20px">
            <h3 style="margin-top: 0px; color: #01314c">我们的使命</h3>
            <p style="text-align: left; color: #666666">
              高效地收集、存储和分析来自不同设备的数据，以帮助客户更好地理解和利用这些数据,力求为用户创造更高的价值，推动产业的数字化转型。
            </p>
          </el-card>
          <el-card shadow="hover" style="margin-top: 20px">
            <h3 style="margin-top: 0px; color: #01314c">我们是谁</h3>
            <p style="text-align: left; color: #666666">
              通过云平台收集各类设备生成的数据，利用大数据技术进行存储、处理和分析，提取有价值的信息。开发基于物联网的数据驱动应用，如智能家居、智能城市、工业自动化等，提升用户的生活和工作效率。
            </p>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "aboutComponent",
  // props: {
  //   msg: String
  // }
  data() {
    return {

    }
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
