<template>
  <div style="overflow: auto;">
    <div id="app">
      <el-container>
        <el-header>
          <div style="font-size: 22px;font-weight: bold;color: #01314c;float: left;">
            广西英斯派尔云计算有限公司
          </div>
        </el-header>
        <el-main>
          <el-row style="text-align: left">
            <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
              <div style="line-height: 32px">
                <span style="color: forestgreen">我们是谁</span>
                <h1 style="color: #01314c">智慧物联网高效应用系统服务商</h1>
                <p style="text-indent: 2em; color: #666666">
                  广西英斯派尔云计算有限公司(以下简称“英斯派尔”)成立于2014年，是一家定位于立足广西、服务全国、辐射东盟的积木化物联网设备提供商和系统集成商。公司聚焦于SaaS、物联网设备、云计算的研发和落地应用，业务领域覆盖智慧农业、智慧城市、社区电商、广播新媒体、IT构架设计和运维服务等。
                </p>
              </div>
              <div style="margin-top: 10%; line-height: 25px">
                <h2 style="color: #01314c">
                  基于自主积木式技术智慧物联应用系统
                </h2>
                <span class="text1">SOC固件底层框架/可复用插件设计</span>
                <p style="text-indent: 2em; color: #666666">
                  设计了创新简洁的固件框架架构，让独立积木驱动/MQTT协议/加解密鉴权等等全部插件化，默认接入阿里云物联网平台，可兼容MQTT协议的各种物联网平台。
                </p>
                <span class="text1">物联硬件积木化</span>
                <p style="text-indent: 2em; color: #666666">
                  实现了主控SOC、供电、传感器、控制器等等部件独立积木化，快速满足场景需求和量身定制，可重复利用，拔插式维护，大量节省各类成本和落地时间。
                </p>
                <span class="text1">独立FOTA子系统</span>
                <p style="text-indent: 2em; color: #666666">
                  除了更安全，还可以让主控SOC在城市乡间、田间地头瞬间更改其逻辑功能、无需技术人员跑来跑去，让普通人都能轻松使用和维护。
                </p>
                <span class="text1">SOC群集+分布式边缘计算</span>
                <p style="text-indent: 2em; color: #666666">
                  无需过多占用网络资源，可独立/群集/分布式直接运算结果，从采集源头开始计算处理，提升性能速度和大量节省带宽/云资源。
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="{span: 10, offset: 4}" :xl="{span: 10, offset: 4}" >
              <div>
                <img src="./assets/static-main-1.png" style="object-fit: contain;max-width: 100%;min-width: 100%;"/>
              </div>
            </el-col >
            <el-col :span="24">
              <h1 style="text-align: center; color: #01314c;margin-top: 60px">我们的特点</h1>
              <el-divider>
                不挑人的友好，应用照顾全面
              </el-divider>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
              <div style="margin-top: 100px">
                <h3>1.友好的“即插即用”和扫码配网</h3>
                <p style="color: #666666">
                  根据实际场景需要，选择合适的传感器积木，对口插上就可用；首次通电使用，只需手机连上WIFI，微信扫一扫，即刻联网。
                </p>
                <h3>2.友好的API和H5调试页</h3>
                <p style="color: #666666">
                  软件开发者很简单就能获取到JSON数据、通过单指令进行传参控制，还有直观的H5可视化调试。
                </p>
                <h3>3.友好的模块化微信生态SaaS平台</h3>
                <p style="color: #666666">
                  拥有成熟的多行业业务运营模块，营销推广模块，物联应用模块，能快速为非IT公司组合或定制落地项目。
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="{span: 10, offset: 4}" :xl="{span: 10, offset: 4}">
              <div style="margin: 0 20%;">
                <img src="./assets/sass.png" style="object-fit: contain;min-width: 100%;max-width: 100%;" />
              </div>
            </el-col>
          </el-row>
          <About />
        </el-main>
      </el-container>
    </div>
    <div>
      <div
        style="background-color: #f7fafd; text-align: left; padding-top: 30px"
      >
        <el-row style="color: #666666;" >
          <h3 style="color: #01314c; text-align: center">
            广西英斯派尔云计算有限公司
          </h3>
          <el-col :xs="{span:20,offset: 2}" :sm="{span:20,offset: 2}" :md="{span:20,offset: 2}" :lg="{span:6,offset:2}" :xl="{span:6,offset:2}">
            <p style="text-indent: 2em;">
              英斯派尔有15年以上的IT系统软硬件开发、实施和运维经验，6年以上系统集成信息化及互联网运营管理、产品营销经验，10年以上种养殖经验。
            </p>
          </el-col>
          <el-col :xs="{span:20,offset: 2}" :sm="{span:20,offset: 2}" :md="{span:20,offset: 2}" :lg="{span:4,offset: 2}" :xl="{span:4,offset: 2}" style="padding-bottom: 30px;text-align:start">
            <div style="margin-top: 15px;">
              <i class="el-icon-message" style="color: green"></i>
              <span style="margin-left: 5px">
                广西壮族自治区南宁市高新区科西十路正鑫科技园正鑫科技大厦9B06
              </span>
            </div>
            <div style="margin-top: 10px">
              <i class="el-icon-phone-outline" style="color: green"></i>
              <span style="margin-left: 10px">电话: 0771-3926813 </span>
              <div style="margin-top: 10px; text-indent: 1.5em;">
                邮箱：Hi@Gxins.com
              </div>
            </div>
          </el-col>
          <el-col :xs="{span:20,offset: 2}" :sm="{span:20,offset: 2}" :md="{span:20,offset: 2}" :lg="{span:6,offset: 3}" :xl="{span:6,offset: 3}" style="padding-top: 15px;padding-bottom: 100px">
            <div>2024 广西英斯派尔云计算有限公司©</div>
            <div>
              <a
                href="http://beian.miit.gov.cn/"
                 class="aStyle"
                >桂ICP备14006427号-13</a
              >
            </div>

            <!-- <div style="margin-top: 20px; padding-bottom: 100px">
              <span><img src="./assets/safe.png" /></span>
              <a
                href="https://beian.mps.gov.cn/#/query/webSearch"
                class="aStyle"
              >
                桂公网安备 45010702000675号
              </a>
            </div> -->
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import About from "./components/About.vue";

export default {
  name: "App",
  components: {
    About,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 2% 5%;
}
.text1 {
  font-size: 18px;
  font-weight: bold;
  color: #01314c;
}
.aStyle{
  text-decoration: none;
  color: #0e314c;
}
.aStyle:hover {
  color: forestgreen;
}
</style>
